<script setup lang="ts">
import { PropType } from 'vue'
import { Category, ContentType, Tag } from '@/models/content'
import IconDownload from '@/assets/icons/download.svg'

const props = defineProps({
  contentType: {
    type: String as PropType<ContentType>,
    required: true,
    validator(value: string) {
      return ['presentation', 'magazine'].includes(value)
    },
  },
  publishedDate: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  quarter: {
    type: String,
    required: false,
  },
  issue: {
    type: String,
    required: false,
  },
  summary: {
    type: String,
    required: false,
  },
  company: {
    type: Object,
    required: false,
  },
  eventName: {
    type: String,
    required: false,
  },
  pills: {
    type: Array as PropType<Tag[] | Category[]>,
    required: true,
  },
  pillsLimit: {
    type: Number,
    default: 2,
  },
  coverUrl: {
    type: String,
    required: true,
  },
  fileName: {
    type: String,
    required: true,
  },
  fileCategory: {
    type: String,
    required: true,
  },
})

const isPresentation = computed(() => props.contentType === 'presentation')
const isMagazine = computed(() => props.contentType === 'magazine')

const limitedPills = computed(() => props.pills.slice(0, props.pillsLimit))
const coverPosition = useCoverPosition(props.coverUrl)

const formattedTitle = computed(() =>
  isMagazine.value
    ? `${props.quarter} | ${props.title} - ${props.issue}`
    : props.title,
)

const { callToAction, isDownloading, downloadProgress } = useDownloadFile()
</script>

<template>
  <article
    class="card-hover-md group bg-s-900"
    @click="callToAction.callback(id, fileCategory, fileName)"
  >
    <div class="relative w-full shrink-0 pb-1/2 lg:h-96 lg:pb-0">
      <img
        width="500"
        height="500"
        :src="$cloudinary.resize(coverUrl, 'featured-latest-news-thumbnail')"
        class="absolute h-full min-w-full object-cover"
        :class="coverPosition"
        alt=""
      />
      <div
        class="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black/80 text-white transition-opacity duration-500 group-hover:opacity-100"
        :class="{ 'opacity-0': !isDownloading }"
      >
        <CircularProgressBar
          v-if="isDownloading || downloadProgress"
          class="bottom-[5px] right-[5px] z-50"
          color="white"
          :progress="downloadProgress"
          :is-loading="isDownloading"
        />
        <p
          v-if="!isDownloading"
          class="mt-6 hidden flex-col items-center text-sm md:mt-0 md:flex md:text-base md:font-semibold"
        >
          <IconDownload class="fill-white" />
          <ClientOnly>
            {{ callToAction.text }}
          </ClientOnly>
        </p>
      </div>

      <div
        v-if="!isDownloading"
        class="absolute flex h-full w-full flex-col items-center justify-center md:hidden"
      >
        <div class="rounded-full bg-black/70 p-2">
          <IconDownload class="h-3 w-3 fill-white" />
        </div>
      </div>

      <div class="absolute right-1 top-1 md:right-3 md:top-3">
        <ContentTypeBadge :type="contentType" />
      </div>

      <div class="absolute top-0 h-full w-full border-2 border-black/10" />
    </div>

    <div class="p-4 md:p-8">
      <p class="text-xs font-semibold text-s-600">
        {{ publishedDate }}
      </p>
      <h3
        class="mt-2 text-lg font-bold leading-snug text-s-150 md:text-4xl md:font-bold"
      >
        {{ formattedTitle }}
      </h3>

      <div
        v-if="isMagazine && summary"
        class="mt-2 line-clamp-6 text-base text-s-150"
        v-html="summary"
      />

      <div v-if="isPresentation" class="mt-2 text-base text-s-700">
        <p v-if="company">
          <span class="font-bold">Company/Institution:</span>
          {{ company.name }}
        </p>
        <p v-if="eventName">
          <span class="font-bold">Event:</span>
          {{ eventName }}
        </p>
      </div>

      <div class="mt-4 flex flex-wrap gap-2">
        <LinkedPill
          v-for="pill in limitedPills"
          :key="pill.id"
          :type="pill.__typename"
          :slug="pill.slug"
          variant="yellow"
        >
          {{ pill.name }}
        </LinkedPill>
      </div>
    </div>
  </article>
</template>
