<script setup lang="ts">
import { getAdaptedChartData } from '@/utils/adapters'
import type { MineralIndicator } from '@/models/content'
import { toNumberWithCommas } from '@/utils/string.js'
import { logError } from '@/utils/log-error'
import IndexesChart from '~/components/charts/IndexesChart.vue'

const { $restClient, $theme } = useNuxtApp()

const colors = [$theme.colors.p['400'], '#FD8900', '#86C6F4', '#8B6CB2']

const lithiumValues = ref<MineralIndicator>({
  type: 'chart',
  updateFrequency: '2WK',
  chartData: null,
  tableData: [],
})

const naturalGraphiteValues = ref<MineralIndicator>({
  type: 'chart',
  chartData: null,
  tableData: [],
})

const syntheticGraphiteValues = ref<MineralIndicator>({
  type: 'chart',
  chartData: null,
  tableData: [],
})

const cathodeValues = ref<MineralIndicator>({
  type: 'card',
  labelProduction: 'Total capacity pipeline',
  labelProducers: 'Cathode producers tracked',
  production: '',
  producers: '',
  unit: 'tpa',
  chartData: null,
  tableData: [],
})

const anodeValues = ref<MineralIndicator>({
  type: 'card',
  labelProduction: 'Total capacity pipeline',
  labelProducers: 'Anode producers tracked',
  production: '',
  producers: '',
  unit: 'tpa',
  chartData: null,
  tableData: [],
})

const lithiumIonBatteriesValues = ref<MineralIndicator>({
  type: 'card',
  labelProduction: 'Total Lithium ion Battery Capacity Pipeline',
  labelProducers: 'Individual Lithium ion Battery Gigafactories Pipeline',
  production: '',
  producers: '',
  unit: 'GWh',
  chartData: null,
  tableData: [],
})

const blackMassValues = ref<MineralIndicator>({
  type: 'chart',
  chartData: null,
  tableData: [],
})

const mineralToShow = ref<MineralIndicator>()

const navBar = ref({
  lithium: {
    data: lithiumValues,
    enabled: false,
    itemName: 'Lithium',
    slug: 'lithium',
  },
  naturalGraphite: {
    data: naturalGraphiteValues,
    enabled: false,
    itemName: 'Natural Graphite',
    slug: 'natural-graphite',
  },
  syntheticGraphite: {
    data: syntheticGraphiteValues,
    enabled: false,
    itemName: 'Synthetic Graphite',
    slug: 'synthetic-graphite',
  },
  anode: {
    data: anodeValues,
    enabled: false,
    itemName: 'Anode',
    slug: 'anode',
  },
  cathode: {
    data: cathodeValues,
    enabled: false,
    itemName: 'Cathode',
    slug: 'cathode',
  },
  battery: {
    data: lithiumIonBatteriesValues,
    enabled: false,
    itemName: 'Battery',
    slug: 'lithium-ion-batteries',
  },
  blackMass: {
    data: blackMassValues,
    enabled: false,
    itemName: 'Black Mass',
    slug: 'black-mass',
  },
})
const selectedClass = ref('!border-p-500 text-white')

async function show(option: string) {
  const selectedMineral = navBar.value[option]
  selectedMineral.data.slug = selectedMineral.slug

  for (const key in navBar.value) {
    navBar.value[key].enabled = false
  }
  selectedMineral.enabled = true

  if (selectedMineral.data.chartData === null) {
    try {
      loading.value = true
      const apiData = await $restClient.getIndexesByProduct(
        selectedMineral.slug,
      )
      selectedMineral.data.chartData = getAdaptedChartData(
        apiData.detail,
        colors,
      )
      selectedMineral.data.tableData = apiData.summary
      loading.value = false
    } catch (error) {
      logError(error)
    }
  }

  mineralToShow.value = selectedMineral.data
  mineralToShow.value.key = option
}

function setCapacitiesDataValues(CapacitiesData) {
  cathodeValues.value.production = toNumberWithCommas(
    CapacitiesData.cathode.production,
  )
  cathodeValues.value.producers = toNumberWithCommas(
    CapacitiesData.cathode.producers,
  )

  anodeValues.value.production = toNumberWithCommas(
    CapacitiesData.anode.production,
  )
  anodeValues.value.producers = toNumberWithCommas(
    CapacitiesData.anode.producers,
  )

  lithiumIonBatteriesValues.value.production = toNumberWithCommas(
    CapacitiesData.gigafactory.production,
    true,
  )
  lithiumIonBatteriesValues.value.producers = toNumberWithCommas(
    CapacitiesData.gigafactory.producers,
  )
}

const loading = ref(true)

onMounted(async () => {
  try {
    setCapacitiesDataValues(await $restClient.getCapacitiesDataIndicators())
  } catch (error) {
    logError(error)
  }
  await show('lithium')
})
</script>

<template>
  <div class="min-h-[1225px] bg-s-900 pb-3 md:min-h-[1151px] md:pb-16">
    <section
      class="mx-auto mt-8 h-full w-full max-w-content-container pt-9 md:mt-16"
    >
      <h2
        class="mb-4 px-5 text-center text-lg font-semibold text-s-50 md:text-2xl md:font-bold"
      >
        Benchmark offers price, data, and consultancy services
      </h2>
      <div class="flex justify-center pb-5">
        <a
          href="https://www.benchmarkminerals.com/"
          class="btn primary-btn flex w-fit items-center justify-center gap-x-3 rounded bg-p-400 px-4 py-2 text-base font-semibold leading-[26px] text-s-900 md:text-lg"
          target="_blank"
          rel="noopener"
        >
          Learn more
        </a>
      </div>
      <div class="flex justify-center">
        <ul
          class="flex flex-wrap justify-center gap-x-2 gap-y-3 px-5 text-base font-semibold text-white/75 sm:gap-x-0 md:text-xl"
        >
          <li
            v-for="(value, key) in navBar"
            :key="value.itemName"
            class="ml-0 cursor-pointer border-b-4 border-b-transparent sm:ml-4 lg:ml-6"
            :class="!value.enabled || selectedClass"
            @click="show(key)"
          >
            {{ value.itemName }}
          </li>
        </ul>
      </div>

      <LoadingSpinner v-if="loading" class="h-2/5" />

      <div v-else-if="mineralToShow" data-testid="mineral-prices">
        <div
          data-testid="mineral-prices-producers-production"
          class="flex min-h-[156px] flex-col flex-wrap justify-center gap-x-10 gap-y-3 px-5 py-7 text-xs font-semibold leading-[22px] text-white sm:min-h-[112px] md:py-4 md:text-base navigation-adjustment:min-h-[56px] navigation-adjustment:flex-row"
        >
          <div
            v-if="mineralToShow.producers"
            class="flex items-center justify-between gap-x-5 border-l-4 border-p-500 pl-1 navigation-adjustment:justify-start"
          >
            <span>{{ mineralToShow.labelProducers }}</span>
            <span class="flex-shrink-0">{{ mineralToShow.producers }}</span>
          </div>
          <div
            v-if="mineralToShow.production"
            class="flex items-center justify-between gap-x-5 border-l-4 border-[#ea8000] pl-1 navigation-adjustment:justify-start"
          >
            <span>{{ mineralToShow.labelProduction }}</span>
            <span class="flex-shrink-0">
              {{ mineralToShow.production }} {{ mineralToShow.unit }}
            </span>
          </div>
        </div>

        <div data-testid="mineral-prices-chart-index" class="min-h-[705px]">
          <div class="w-full bg-white">
            <div
              class="relative mx-auto min-h-96 w-[99%] px-2 pb-4 pt-4 lg:p-8"
            >
              <IndexesChart
                :key="mineralToShow.key"
                y-axis-title="Index Value"
                :show-line-points="false"
                :data="mineralToShow.chartData"
                :turn-off-animation="!!navBar.lithium.enabled"
              />
              <p class="mt-2 text-right text-[7.8px] font-normal text-s-900">
                *Index Values
              </p>
            </div>
            <MineralIndicatorTable
              :data="mineralToShow.tableData"
              :slug="mineralToShow.slug"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
