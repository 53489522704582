<script setup lang="ts">
import type { PropType } from 'vue'
import { useSwipe } from '@vueuse/core'
import type { AnyContent, Article } from '@/models/content'

const props = defineProps({
  articles: {
    type: Array as PropType<Article[]>,
    required: true,
  },
})

const { $cloudinary } = useNuxtApp()

const hero = ref<HTMLElement | null>(null)
const activeIndex = ref(0)
const activeIndexState = ref('active')
const activeArticle = computed(() =>
  props.articles ? props.articles[activeIndex.value] : null,
)

const isTabletOrDesktop = useTabletOrDesktop()

const { direction } = useSwipe(hero)

watch(direction, (value) => {
  if (!isTabletOrDesktop) return
  if (value === 'left') next()
  if (value === 'right') prev()
})

useMobile(() => (activeIndex.value = 0))

const pause = () => (activeIndexState.value = 'paused')
const resume = () => (activeIndexState.value = 'active')
const change = (index) => (activeIndex.value = index)

const next = () => {
  const nextIndex = activeIndex.value + 1
  change(nextIndex % props.articles.length)
}

const prev = () => {
  const prevIndex = activeIndex.value + (props.articles.length - 1)
  change(prevIndex % props.articles.length)
}

const getIndexState = (index) => {
  if (index === activeIndex.value) {
    return activeIndexState.value
  }
}

const arrayPills = (data: AnyContent) => {
  return useFormattedPillsData(data)
}
</script>

<template>
  <!-- Active Article -->
  <div
    v-if="activeArticle"
    ref="hero"
    data-testid="active-article"
    class="relative"
    @mouseover="pause"
    @mouseleave="resume"
  >
    <!-- Article Cover -->
    <div class="relative pb-1/2 md:h-[486px] md:pb-0">
      <picture>
        <source
          media="(min-width: 3000px)"
          :srcset="$cloudinary.resize(activeArticle.coverUrl, 'home-hero-4k')"
          class="absolute h-full min-w-full object-cover"
        />

        <source
          media="(min-width: 2000px)"
          :srcset="$cloudinary.resize(activeArticle.coverUrl, 'home-hero-hd')"
          class="absolute h-full min-w-full object-cover"
        />

        <source
          media="(min-width: 640px)"
          :srcset="$cloudinary.resize(activeArticle.coverUrl, 'home-hero')"
          class="absolute h-full min-w-full object-cover"
        />

        <source
          media="(min-width: 200px)"
          :srcset="
            $cloudinary.resize(activeArticle.coverUrl, 'home-hero-mobile')
          "
          class="absolute h-full min-w-full object-cover"
        />

        <img
          width="1000"
          height="500"
          :src="$cloudinary.resize(activeArticle.coverUrl, 'home-hero')"
          :alt="activeArticle.title"
          class="absolute h-full min-w-full object-cover"
        />
      </picture>

      <!-- Overlay -->
      <div
        class="absolute left-0 top-0 hidden h-full w-full bg-black opacity-50 md:block"
      />
    </div>

    <!-- Article Info -->
    <div
      class="w-full pt-3 md:absolute md:left-0 md:top-0 md:py-24 md:text-white"
    >
      <FeaturedGeneralCard
        class="mx-auto max-w-content-container px-5"
        :published-date="activeArticle.publishedDate"
        content-type="article"
        :slug="activeArticle.slug"
        :duration="activeArticle.duration"
        :title="activeArticle.title"
        :excerpt="activeArticle.excerpt"
        :pills="arrayPills(activeArticle)"
        title-classes="text-xl md:text-5xl"
      />
    </div>
  </div>

  <!-- Headlines -->
  <nav
    class="mx-auto mt-5 max-w-content-container grid-cols-4 gap-x-5 px-5 md:mt-0 md:grid"
  >
    <HeroHeadline
      v-for="(article, index) in articles"
      :key="index"
      :published-date="article.publishedDate"
      :duration="article.duration"
      :slug="article.slug"
      :headline="article.title"
      :cover-url="article.coverUrl"
      :state="getIndexState(index)"
      class="border-t-2 border-s-200 first:hidden md:border-none md:first:block"
      @time-up="next"
      @pause="pause"
      @resume="resume"
      @select="change(index)"
    />
  </nav>
</template>
