<script setup lang="ts">
import { CHART_ARTICLE_TAG } from '@/constants/content'
import type { AnyContent, Article, Video } from '@/models/content'
import { useGoogleAnalyticsPageView } from '~/composables/google-analytics'

const MAX_CHART_ARTICLES = 4
const MAX_VIDEOS = 3

const { $graphqlClient, $seoManager } = useNuxtApp()

const loadingPartOne = ref(true)
const loadingPartTwo = ref(true)
const loadingPartThree = ref(true)

const featuredNews = ref<Article[]>()
const latestContents = ref<AnyContent[]>()
const chartArticles = ref<Article[]>()
const featuredChartArticles = ref<Article[]>()
const featuredVideo = ref<Video>()
const videos = ref<Video[]>()
const moreFromBenchmarkContents = ref<Article[]>()

let featuredNewsIds = []
let latestContentIds = []
let chartIds = []
let videosIds = []

const fetchPartOne = async () => {
  loadingPartOne.value = true

  /*-------------------------------------
    SECTION: HERO
  -------------------------------------*/
  featuredNews.value = await $graphqlClient.getFeaturedArticles({
    itemsPerQuery: 4,
  })
  featuredNewsIds = featuredNews.value.map((content) => content.id)

  loadingPartOne.value = false
}

const fetchPartTwo = async () => {
  loadingPartTwo.value = true

  const [
    responseLatestContentResults,
    responseChartArticlesResults,
    responseFeaturedChartArticlesResults,
    responseFeaturedVideoResults,
    responseVideoResults,
  ] = await Promise.allSettled([
    $graphqlClient.getArticlesAndVideos({
      itemsPerQuery: 12,
      excluded: featuredNewsIds,
    }),
    $graphqlClient.getArticlesByTags({
      tags: [CHART_ARTICLE_TAG],
      itemsPerQuery: MAX_CHART_ARTICLES,
    }),
    $graphqlClient.getFeaturedArticlesByTags({
      tags: [CHART_ARTICLE_TAG],
      itemsPerQuery: 1,
    }),
    $graphqlClient.getFeaturedVideos({
      itemsPerQuery: MAX_VIDEOS,
    }),
    $graphqlClient.getVideos({
      itemsPerQuery: MAX_VIDEOS,
    }),
  ])

  const latestContentResults = responseLatestContentResults.value.data || []
  const chartArticlesResults = responseChartArticlesResults.value || []
  const featuredChartArticlesResults =
    responseFeaturedChartArticlesResults.value || []
  const featuredVideoResults = responseFeaturedVideoResults.value || []
  const videoResults = responseVideoResults.value.data || []

  /*-------------------------------------
    SECTION: LATEST CONTENT
  -------------------------------------*/
  latestContents.value = latestContentResults
  latestContentIds = latestContentResults.map((content) => content.id)

  /*-------------------------------------
    SECTION: CHARTS
  -------------------------------------*/
  chartArticles.value = chartArticlesResults
  featuredChartArticles.value = featuredChartArticlesResults

  chartIds = [...chartArticles.value, ...featuredChartArticles.value].map(
    (article) => article.id,
  )

  /*-------------------------------------
    SECTION: VIDEOS
  -------------------------------------*/
  videos.value = featuredVideoResults

  if (videos.value.length < 3) {
    const { data: videosData } = videoResults

    videos.value = videos.value.concat(
      videosData.slice(0, MAX_VIDEOS - videos.value.length),
    )
  } else {
    videos.value = videos.value.slice(0, MAX_VIDEOS)
  }

  videosIds = videos.value.map((video) => video.id)
  featuredVideo.value = videos.value.shift()

  loadingPartTwo.value = false
}

const fetchPartThree = async () => {
  loadingPartThree.value = true

  /*-------------------------------------
    SECTION: MORE FROM BENCHMARK
  -------------------------------------*/
  const { data: moreFromBenchmarkResults } = await $graphqlClient.getArticles({
    itemsPerQuery: 8,
    excluded: [
      ...featuredNewsIds,
      ...latestContentIds,
      ...chartIds,
      ...videosIds,
    ],
    detailed: false,
  })

  moreFromBenchmarkContents.value = moreFromBenchmarkResults

  loadingPartThree.value = false
}

watch(loadingPartOne, (_, loaded) => loaded && fetchPartTwo())
watch(loadingPartTwo, (_, loaded) => loaded && fetchPartThree())

onMounted(fetchPartOne)
const seo = $seoManager.generateSeoInfo({ title: 'Home' })
useHead(seo)
useGoogleAnalyticsPageView(seo.title)
</script>

<template>
  <div class="mb-14 flex grow flex-col md:mb-25">
    <LoadingSpinner v-if="loadingPartOne" wrap-screen />

    <div v-else class="grow">
      <!-- Part One -->
      <section v-if="!loadingPartOne">
        <TheHero :articles="featuredNews" />
      </section>

      <!-- Part Two -->
      <LoadingSpinner v-if="!loadingPartOne && loadingPartTwo" />
      <section v-if="!loadingPartTwo">
        <LatestContentSection
          v-if="latestContents.length"
          :contents="latestContents"
        />

        <ChartsSection
          v-if="chartArticles.length"
          :articles="chartArticles"
          :featured-articles="featuredChartArticles"
          :max-articles="MAX_CHART_ARTICLES"
          data-testid="data-visualization-articles"
        />

        <MoreFromBenchmark />

        <VideosSection
          v-if="Object.keys(featuredVideo).length && videos.length"
          :featured-video="featuredVideo"
          :videos="videos"
        />
      </section>

      <!-- Part Three -->
      <LoadingSpinner
        v-if="!loadingPartOne && !loadingPartTwo && loadingPartThree"
      />
      <section v-if="!loadingPartThree">
        <MoreFromBenchmarkSection
          v-if="moreFromBenchmarkContents.length"
          data-testid="more-from-benchmark"
          :contents="moreFromBenchmarkContents"
        />
      </section>
    </div>
  </div>
</template>

<style scoped>
.show-first-three:nth-child(n + 4) {
  display: none;
}

/*
  The following Tailwind function will make Volar, which
  doesn't fully support that syntax, to show a known
  error.

  See: https://github.com/johnsoncodehk/volar/issues/791
*/
@media screen(md) {
  /* stylelint-disable-next-line selector-class-pattern */
  .md\:show-all:nth-child(n) {
    display: flex;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .md\:hide-2nd-card-border:nth-child(2) {
    @apply border-none;
  }
}

@media screen(lg) {
  /* stylelint-disable-next-line selector-class-pattern */
  .lg\:show-all:nth-child(n) {
    display: flex;
  }
}
</style>
