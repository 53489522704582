<script setup lang="ts">
import { PropType } from 'vue'
import { Category, Tag, Thumbnails } from '@/models/content'

const props = defineProps({
  slug: {
    type: [String, Number],
    required: true,
  },
  publishedDate: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  excerpt: {
    type: String,
    required: true,
  },
  pills: {
    type: Array as PropType<Tag[] | Category[]>,
    required: true,
  },
  coverImages: {
    type: Object as PropType<{
      coverUrl: string
      thumbnails?: Thumbnails
    }>,
    required: true,
  },
})

const href = useContentHref('article', props.slug)

const thumbnail =
  props.coverImages.thumbnails?.squareUrl || props.coverImages.coverUrl

const sizeImage = computed(() =>
  props.coverImages.thumbnails?.squareUrl
    ? 'featured-chart-thumbnail'
    : 'featured-thumbnail',
)
</script>

<template>
  <BaseLink
    :href="href"
    data-testid="data-visualization-link"
    as="a"
    variant="unstyled"
    class="card-hover h-full w-full"
  >
    <article
      class="card-hover-md relative h-full w-full items-stretch bg-warm-gray"
    >
      <div
        class="relative right-0 top-0 h-full max-h-96 md:absolute md:max-h-full md:w-1/2"
      >
        <div class="relative h-full w-full bg-s-50">
          <img
            :src="$cloudinary.resize(thumbnail, sizeImage)"
            width="400"
            height="400"
            class="hidden h-full w-full object-contain object-center md:block md:max-h-full md:max-w-full"
            alt="Article image preview"
            :class="thumbnail === coverImages.coverUrl && 'object-cover'"
          />
        </div>

        <img
          :src="$cloudinary.resize(thumbnail, 'twitter-card')"
          class="mx-auto h-full max-h-96 object-cover md:hidden md:max-h-full"
          alt="Article image preview"
        />

        <div class="prevent-background-bleed" />

        <div class="absolute right-1 top-1 flex md:right-3 md:top-3">
          <ChartBadge />
        </div>
      </div>

      <div class="p-4 md:w-1/2 md:p-8">
        <p class="text-xs font-semibold text-s-500">
          {{ publishedDate }}
        </p>
        <h3
          class="mt-2 text-2xl font-semibold leading-snug text-black md:font-bold"
        >
          {{ title }}
        </h3>
        <div
          class="mt-4 line-clamp-4 text-base text-black"
          v-html="excerpt"
        ></div>
        <div class="mt-4 flex gap-2">
          <LinkedPill
            v-for="pill in pills"
            :key="pill.id"
            :type="pill.__typename"
            :slug="pill.slug"
            variant="black"
          >
            {{ pill.name }}
          </LinkedPill>
        </div>
      </div>
    </article>
  </BaseLink>
</template>
