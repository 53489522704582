<script setup lang="ts">
import { PropType } from 'vue'
import { AnyContent } from '@/models/content'
import LatestContentGeneralCard from '@/components/cards/LatestContentGeneralCard.vue'
import LatestContentDownloadCard from '@/components/cards/LatestContentDownloadCard.vue'
import HorizontalGeneralCard from '@/components/cards/HorizontalGeneralCard.vue'
import HorizontalDownloadCard from '@/components/cards/HorizontalDownloadCard.vue'

const props = defineProps({
  contents: {
    type: Array as PropType<AnyContent[]>,
    required: true,
  },
})

const firstOfLatestContents = ref(props.contents[0])
const remainingLatestContents = props.contents.slice(1)

const featuredCards = {
  article: LatestContentGeneralCard,
  video: LatestContentGeneralCard,
  presentation: LatestContentDownloadCard,
  magazine: LatestContentDownloadCard,
}

const cards = {
  article: HorizontalGeneralCard,
  video: HorizontalGeneralCard,
  presentation: HorizontalDownloadCard,
  magazine: HorizontalDownloadCard,
}

const arrayPills = (data: AnyContent) => {
  return useFormattedPillsData(data)
}
</script>

<template>
  <BaseSection title="Latest">
    <div class="relative items-start gap-6 lg:flex">
      <section class="lg:w-1/2">
        <component
          :is="featuredCards[firstOfLatestContents.contentType]"
          v-bind="firstOfLatestContents"
          :pills="arrayPills(firstOfLatestContents)"
        />
      </section>

      <section
        class="right-0 top-0 mt-5 h-full overflow-y-auto lg:absolute lg:mt-0 lg:w-1/2 lg:pl-6 lg:pr-8"
      >
        <component
          :is="cards[content.contentType]"
          v-for="content in remainingLatestContents"
          :key="content.id"
          v-bind="content"
          :pills="arrayPills(content)"
          class="show-first-three lg:show-all border-t-2 border-s-200 first:border-none first:pt-0"
        />
      </section>
    </div>

    <div class="mt-2 flex justify-center md:mt-7">
      <BaseLink variant="tertiary" href="/search">
        <span class="md:hidden">See more</span>
        <span class="hidden md:block">Click to view more from Benchmark</span>
      </BaseLink>
    </div>
  </BaseSection>
</template>
